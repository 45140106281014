import { template as template_b46c9f5dfde7435b91f4b825be6828eb } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllCategoriesSectionLink = template_b46c9f5dfde7435b91f4b825be6828eb(`
  <SectionLink
    @linkName="all-categories"
    @content={{i18n "sidebar.all_categories"}}
    @route="discovery.categories"
    @prefixType="icon"
    @prefixValue="sidebar.all_categories"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllCategoriesSectionLink;
