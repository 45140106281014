import { template as template_c40bb1b5d6ff46109d776ac9ee52e073 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const ActionList = template_c40bb1b5d6ff46109d776ac9ee52e073(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
