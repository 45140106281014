import { template as template_72b0e8c22d634c569316b9ec6898450c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_72b0e8c22d634c569316b9ec6898450c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
